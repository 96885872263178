import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { LiveScore } from '../models/livescore';
import { ShowStream } from './show-stream';

interface MatchParams {
    id: string;
    width: string;
}

interface Props extends RouteComponentProps<MatchParams> {

}

interface State {
    isReady: boolean;
    liveScore?: LiveScore;
    fontSize?: number;
    shorten?: boolean;
    upperCase?: boolean;
    delay?: number;
}

export class StreamTable extends Component<Props, State> {

    private tableId: string;
 
    constructor(props: Props) {
        super(props);

        this.tableId = this.props.match.params.id;


        let urlSearch = new URLSearchParams(this.props.location.search);

        let fontSize = undefined;
        if (urlSearch.has("fontSize")) {
            fontSize = parseInt(urlSearch.get("fontSize")!, 10);
        }
        let shorten = undefined;
        if (urlSearch.has("shorten")) {
            shorten = parseInt(urlSearch.get("shorten")!) === 1;
            console.log("Shorten", shorten);
        }
        let upperCase = undefined;
        if (urlSearch.has("upperCase")) {
            upperCase = parseInt(urlSearch.get("upperCase")!) === 1;
        }
        let delay = undefined;
        if (urlSearch.has("delay")) {
            delay = parseInt(urlSearch.get("delay")!);
        }

        this.state = {
            isReady: false,
            delay: delay,
            fontSize: fontSize,
            shorten: shorten,
            upperCase: upperCase
        };
    }

    render() {
        return (
            <div>
                <ShowStream tableId={this.tableId} fontSize={this.state.fontSize} upperCase={this.state.upperCase} shorten={this.state.shorten} delay={this.state.delay}></ShowStream>
                  <div style={{ height: 8800 }}>

                </div>
            </div>
        );
    }

}