import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import cuescoreService from '../api/CuescoreService';
import httpService from '../api/HttpService';
import { CS_Match, CS_Tournament } from '../models/cuescore-models';
import { LiveScore } from '../models/livescore';
import { MatchStartRequest } from '../models/match-start-request';
import { Organization } from '../models/organization';
import { CuescoreMatch } from './cuescore-match';
import { StartMatchModal } from './start-match-modal';

interface TournamentCardProps {
    organizationId: string;
}

interface TournamentCardState {
    tournamentId: string;
    organization?: Organization;
    tournament?: CS_Tournament;
    showModal: boolean;
    selectedMatch?: CS_Match;
    liveLicenses?: LiveScore[];
    matchStartRequests?: MatchStartRequest[];
}

export class TournamentCard extends Component<TournamentCardProps, TournamentCardState> {

    constructor(props: TournamentCardProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            tournamentId: "",
            showModal: false
        };

    }

    async componentDidMount() {
        this.loadMatchStartRequests();    
        let org = await this.loadOrganization();
        if (org && org.tournamentIdentifier)    {
            this.loadData(parseInt(org.tournamentIdentifier));
        }
    }

    handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ tournamentId: e.target.value });
    }

    private matchStartClicked = (match: CS_Match) => {
        console.log("Match start");
        if (match) {
            this.setState({ showModal: true, selectedMatch: match });
            this.loadLiveLicenses();
            this.loadMatchStartRequests();
        }
    }

    renderMatches(matches: CS_Match[]): JSX.Element {
        return (
            <div>
                <h3>Matches</h3>
                <div>
                    {matches.map((match: CS_Match) =>
                        //
                        <CuescoreMatch match={match} matchStartRequests={this.state.matchStartRequests} onClick={this.matchStartClicked}></CuescoreMatch>
                    )}
                </div>
            </div>
        );
    }

    render() {

        if (!this.state.organization) {
            return <p>Loading...</p>
        }
        if (this.state.organization && !this.state.organization.organizationOptions.tournamentsEnabled) {
            return <div></div>;
        }

        let startableMatches = this.state.tournament?.matches.filter(x => x.matchstatusCode == 0 && x.playerA.name && x.playerB.name).sort(function (a, b) {
            var c = new Date(a.starttime);
            
            var d = new Date(b.starttime);
            return c.getTime() - d.getTime();
        });
        const content = startableMatches && this.renderMatches(startableMatches);

        return (
            <div className="card">
                <div className="card-body">
                    <h5 className="card-title">Tournaments</h5>
                    <Form>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Cuescore tournament ID</Form.Label>
                            <Form.Control type="number" name="username" placeholder="Tournament ID" value={this.state.tournamentId} onChange={this.handleChange} />
                        </Form.Group>
                        <Button variant="primary" onClick={() => this.fetchTournamentClick()}>
                            Fetch tournament
                    </Button>
                    </Form>
                    {this.state.tournament && this.state.tournament.name}
                    {content}
                </div>
                { this.state.selectedMatch &&
                    <StartMatchModal liveLicenses={this.state.liveLicenses} tournamentId={this.state.tournament?.tournamentId} tournamentName={this.state.tournament?.name} organizationId={this.props.organizationId} matchStartRequests={this.state.matchStartRequests} match={this.state.selectedMatch} show={this.state.showModal} onMatchStarted={() => this.loadMatchStartRequests()} onClose={() => this.setState({ showModal: false })}></StartMatchModal>
                }
            </div>
        );
    }

    private async fetchTournamentClick() {
        await this.loadData(parseInt(this.state.tournamentId));
        if (this.state.organization) {
            let org = this.state.organization;
            org.tournamentIdentifier = this.state.tournamentId;
            let response = httpService.put<Organization>("api/organizations", this.props.organizationId, org);
        }
    }

    private async loadData(id: number) {
        const data = await cuescoreService.getTournament(id + "");
        this.setState({ tournament: data });



        console.log(data);
    }

    private loadOrganization = async (): Promise<Organization> => {
        const url = `api/organizations/${this.props.organizationId}`;
        console.log(url);
        let org = await httpService.get<Organization>(url);

        this.setState({ organization: org });

        return org;
    }

    private loadMatchStartRequests = async() => {
        const url = `api/MatchStartRequests?organizationId=${this.props.organizationId}`;
        let requests = await httpService.get<MatchStartRequest[]>(url);

        this.setState({matchStartRequests: requests});
    }

    private loadLiveLicenses = async () => {

        const url = `api/livescores?organizationId=${this.props.organizationId}`

        const liveLicenses = await httpService.get<LiveScore[]>(url);

        this.setState({
            liveLicenses: liveLicenses.sort((a: LiveScore, b: LiveScore) => {
                var regex = /\d+/g;
                var aNumber = a.tableName.match(regex);
                var bNumber = b.tableName.match(regex);

                if (aNumber && bNumber) {
                    return parseInt(aNumber[0]) - parseInt(bNumber[0]);
                }
                else {
                    return -1;
                }
            })
        });
    }
}