import React, { FocusEventHandler, useReducer, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { LicenseEntity } from '../models/license-entity';
import { Col, Row } from 'react-bootstrap';
import httpService from '../api/HttpService';
import { FocusEvent } from 'react'
import { StreamTable } from './stream-table';
import { ShowStream } from './show-stream';
import { useEffect } from 'react';

interface Props {
    onClose?: () => void;
    license?: LicenseEntity;
}

export const StreamOverlayModal = (props: Props) => {
    const p = props;
    let [fontSize, setFontSize] = useState(20);
    let [delay, setDelay] = useState(0);
    let [shorten, setShorten] = useState(false);
    let [uppercase, setUppercase] = useState(false);
    let [isCopied, setIsCopied] = useState(false);
    let [search, setSearch] = useState(`?fontSize=${fontSize}&shorten=${shorten ? 1 : 0}&upperCase=${uppercase ? 1 : 0}&delay=${delay}`);
    let [previewWidth, setPreviewWidth] = useState(1200);

    let link = `${httpService.BaseUrl}stream/${props.license?.id}${search}`;

    const handleFocus = (event: FocusEvent<any>) => event.target.select();
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        setIsCopied(true);
    };

    const close = () => {
        setIsCopied(false);
        if (props.onClose) {
            props.onClose();
        }
    }

    useEffect(() => {
        setSearch(`?fontSize=${fontSize}&shorten=${shorten ? 1 : 0}&upperCase=${uppercase ? 1 : 0}&delay=${delay}`);
    }, [fontSize, shorten, uppercase, delay]);

    return (
        <div>
            {
                props.license ?
                    <Modal dialogClassName="stream-modal" show={props.license} centered={true} onHide={close}>
                        <Modal.Header closeButton>
                            <Modal.Title>Stream overlay for {props.license.name}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={3}>
                                    <label>Font size:</label>
                                </Col>
                                <Col md={3}>
                                    <input type="number" className="form-control" placeholder="Font size" aria-label="License name" aria-describedby="basic-addon1" value={fontSize} onChange={e => setFontSize(parseInt(e.target.value))} />
                                </Col>
                                <Col md={3}>
                                    <label>Delay (in seconds):</label>
                                </Col>
                                <Col md={3}>
                                    <input type="number" className="form-control" placeholder="Delay" aria-label="License name" aria-describedby="basic-addon1" value={delay} onChange={e => setDelay(parseInt(e.target.value))} />
                                </Col>
                            </Row>
                            <Row className="my-4">
                                <Col md={4}>
                                    <label>Shorten first name:</label>
                                </Col>
                                <Col md={2}>
                                    <input className="form-check-input" checked={shorten} onClick={() => setShorten(!shorten)} type="checkbox"></input>

                                </Col>
                                <Col md={4}>
                                    <label>Uppercase:</label>
                                </Col>
                                <Col md={2}>
                                    <input className="form-check-input" checked={uppercase} onClick={() => setUppercase(!uppercase)} type="checkbox"></input>

                                </Col>
                            </Row>



                            <p className="mt-4">Copy the link below and paste it into the browser plugin in your broadcasting software (clicking it will automatically copy it to your clipboard):</p>

                            <div className="d-flex">
                                <input type="text" className="form-control" style={{ fontSize: 13 }} aria-label="License name" aria-describedby="basic-addon1" onClick={copyToClipboard} onFocus={handleFocus} value={link} />
                                <img height={28} src="/content-copy.png" className="ml-2" onClick={copyToClipboard}></img>
                            </div>
                            <div style={{ height: 20, margin: 10 }}>
                                {isCopied &&
                                    <b>Copied to clipboard!</b>
                                }
                            </div>

                            <div className="stream-preview">
                                <h4>Preview</h4>
                                <div className="mt-4">
                                    <div className="zoomed" style={{ width: previewWidth }}>
                                        <ShowStream tableId={props.license.id} fontSize={fontSize} delay={delay} shorten={shorten} upperCase={uppercase}></ShowStream>
                                    </div>
                                </div>
                                <Row>
                                    <Col md={3}>
                                        <label>Preview width:</label>
                                    </Col>
                                    <Col md={3}>
                                        <input type="number" className="form-control" placeholder="Font size" aria-label="License name" aria-describedby="basic-addon1" value={previewWidth} onChange={e => setPreviewWidth(parseInt(e.target.value))} />
                                    </Col>
                                </Row>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={close}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    :
                    <div></div>
            }
        </div>

    );
};