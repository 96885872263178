import React from 'react';
import { LiveScore } from '../models/livescore';
import { LivescoreService } from '../api/LivescoreService';
import { useState } from 'react';
import { useEffect } from 'react';

interface Props {
    // urlSearch: string;
    tableId: string;
    fontSize?: number;
    shorten?: boolean;
    upperCase?: boolean;
    delay?: number;
}

export const ShowStream = (props: Props) => {   
    let [liveScore, setLiveScore] = useState<LiveScore>();

    useEffect(() => {
        console.log("Hello");
        livescoreService.start();
        return function cleanup() {
            livescoreService.stop();
        }
    }, []);

    const callbackFunction = (livescore: LiveScore): void => {
        setLiveScore(livescore);
    }

    const livescoreService = new LivescoreService(props.tableId, 2, props.delay ?? 0, callbackFunction);

    const renderBallsInBreakView = (balls: number[], b: number): JSX.Element => {    
        return (
            <div className="balls-container">
                <div className="mr-2">Break: {b}</div>
                {balls.map((ball: number) => {
                    const classes: string = `ball ball-${ball}`;
                    return (
                        <div className={classes}></div>
                    );
                }
                )}
            </div>
        );
    }

    const renderScore = (liveScore: LiveScore, shorten?: boolean, upperCase?: boolean, fontSize?: number): JSX.Element => {
        const player1ActiveClass = liveScore.player1IsActive ? "custom-livescore-active" : "custom-livescore-inactive";
        const player2ActiveClass = liveScore.player2IsActive ? "custom-livescore-active" : "custom-livescore-inactive";
        const player1Score = liveScore.player1Score + liveScore.player1Break;
        const player2Score = liveScore.player2Score + liveScore.player2Break;

        let ballsInBreak: JSX.Element = <div></div>;
        const player1BallsInBreak: number[] = JSON.parse(liveScore.player1BallsInBreak);
        const player2BallsInBreak: number[] = JSON.parse(liveScore.player2BallsInBreak);

        if (player1BallsInBreak.length > 0) {
            ballsInBreak = renderBallsInBreakView(player1BallsInBreak, liveScore.player1Break);
        }
        if (player2BallsInBreak.length > 0) {
            ballsInBreak = renderBallsInBreakView(player2BallsInBreak, liveScore.player2Break);
        }

        let player1FirstName = liveScore.player1FirstName;
        if (shorten) {
            player1FirstName = liveScore.player1FirstName[0] + ".";
        }
        let player2FirstName = liveScore.player2FirstName;
        if (shorten) {
            player2FirstName = liveScore.player2FirstName[0] + ".";
        }
        
        const containerClassname = "custom-livescore-container" + (upperCase ? " upper-case" : "");
        return (
            <div>
                <div className="d-flex flex-row justify-content-center">
                    <div className={containerClassname}>
                        <div className="custom-livescore-player">
                            <div className="custom-livescore-playername"  style={{ fontSize: fontSize }}>
                                <div className={player1ActiveClass}></div>
                                <div>{player1FirstName} {liveScore.player1LastName}</div>
                            </div>
                            <div className="custom-livescore-score">{player1Score}</div>
                        </div>
                        <div className="custom-livescore-framescore">
                            <div className="custom-livescore-player-frames">{liveScore.player1FramesWon}</div>
                            <div className="custom-livescore-distance">({liveScore.distance})</div>
                            <div className="custom-livescore-player-frames">{liveScore.player2FramesWon}</div>
                        </div>
                        <div className="custom-livescore-player">
                            <div className="custom-livescore-score">{player2Score}</div>
                            <div className="custom-livescore-playername"  style={{ fontSize: fontSize }}>
                                <div>{player2FirstName} {liveScore.player2LastName}</div>
                                <div className={player2ActiveClass}></div>
                            </div>

                        </div>
                    </div>
                </div>
                {ballsInBreak}
                <div className="d-flex flex-row justify-content-center ">
                    <div className="d-flex flex-row justify-content-center custom-powered-label">Livescore powered by 147board.com</div>
                </div>
              
            </div>
        );
    }
  
    if (liveScore) {
        return renderScore(liveScore, props.shorten, props.upperCase, props.fontSize);
    }
    else {
        return (<div>No</div>);
    }
}