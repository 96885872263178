import React from 'react';
import './App.css';
import 'bootswatch/dist/lumen/bootstrap.min.css';
import { Layout } from './components/layout';
import { Route, Router } from 'react-router';
import { Register } from './components/register';
import { BrowserRouter } from 'react-router-dom';
import { Login } from './components/login';
import { Dashboard } from './components/dashboard';
import { Live } from './components/live';
import { Home } from './components/home';
import { Pricing } from './components/pricing';
import { About } from './components/about';
import { Admin } from './components/admin';
import { EditOrganization } from './components/edit-organization';
import { EditSKin } from './components/edit-skin';
import { StreamTable } from './components/stream-table';
import { Download } from './components/download';
import { Registration } from './components/registration';
import { ResetPassword } from './components/reset-password';
import { ChangePassword } from './components/change-password';
import { OrderCompleted } from './components/order-completed';
import { TermsOfService } from './components/tos';
import { PrivacyPolicy } from './components/privacy';
import { LiveOverview } from './components/live-overview';
import { StreamGuide } from './components/stream-guide';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
       <Route exact path='/stream/:id' component={StreamTable} />
        <Layout>
         
          <Route exact path='/' component={Home} />
          <Route exact path='/pricing' component={Pricing} />
          <Route exact path='/about' component={About} />
          <Route path='/login' component={Login} />
          <Route path='/register' component={Register} />
          <Route exact path='/dashboard' component={Dashboard} />
          <Route path='/dashboard/:id' component={Dashboard} />
          <Route exact path='/organization/:id' component={EditOrganization} />
          <Route exact path='/organization/skin/:orgId' component={EditSKin} />
          <Route exact path='/live' component={LiveOverview} />
          <Route path='/live/:id' component={Live} />
          <Route path='/admin' component={Admin} />
          <Route path='/download' component={Download} />
          <Route exact path='/registration/:id' component={Registration} />
          <Route exact path='/reset-password/:email' component={ResetPassword} />
          <Route exact path='/reset-password/' component={ResetPassword} />
          <Route exact path='/change-password/:resetId' component={ChangePassword} />
          <Route exact path='/order-completed' component={OrderCompleted} />
          <Route exact path='/tos' component={TermsOfService} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/stream-guide" component={StreamGuide} />
        </Layout>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
